import React from "react";
import IconAddmoney from "../../assets/images/IconAddmoney";
import IconAEPS from '../../assets/images/IconAEPS';
import IconBillRecharge from "../../assets/images/IconBillRecharge";
import IconBroadband from "../../assets/images/IconBroadband";
import IconDTH from "../../assets/images/IconDTH";
import IconExploreMore from "../../assets/images/IconExploreMore";
import IconMobileRecharge from "../../assets/images/IconMobileRecharge";
import IconSendmoney from "../../assets/images/IconSendmoney";
import IconCredit from '../../assets/images/IconCredit';
import IconWater from "../../assets/images/IconWater";
import IconGas from "../../assets/images/IconGas";

const iconMap = {
  IconAEPS: <IconAEPS color={"#e3871e"} />,
  IconMobileRecharge: <IconMobileRecharge color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconBillRecharge: <IconBillRecharge color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconBroadband: <IconBroadband color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconDTH: <IconDTH color={"#2572EF"} />,
  IconSendmoney: <IconSendmoney color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconAddmoney: <IconAddmoney color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconCreditCard: <IconCredit color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconWater: <IconWater color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconExploreMore: <IconExploreMore color={"#2572EF"} color_2={"#a9a1a1"} />,
  IconGas: <IconGas color={"#2572EF"} color_2={"#a9a1a1"} />
}

const TabNavItem = ({ id, title, isActive, setActiveTab, icon = "" }) => {
 
 
return (
   <div onClick={() =>setActiveTab(id)} className={isActive ? "nav-link active" : "nav-link"}>
    <div className="pic">
		{iconMap?.[icon]}
	</div>
		<span>{title}</span>
   </div>
 );
};
export default TabNavItem;